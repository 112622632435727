var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-header header-shadow", class: _vm.headerbg },
    [
      _c("div", { staticClass: "logo-src" }),
      _c("div", { staticClass: "app-header__content" }, [
        _c(
          "div",
          { staticClass: "app-header-left" },
          [
            _c("div", { staticClass: "top-left-hamburger-left" }),
            _c("div", { staticClass: "header__pane ml-auto menucollapse" }, [
              _c(
                "button",
                {
                  staticClass: "hamburger close-sidebar-btn hamburger--elastic",
                  class: { "is-active": !_vm.isOpen },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleBodyClass("closed-sidebar")
                    },
                  },
                },
                [_vm._m(0)]
              ),
            ]),
            _c("TabMenus"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "app-header-right" },
          [
            _c("SiteMap"),
            _c("ShortTabMenus"),
            _c("HeaderZoom"),
            _c("HeaderDots"),
            _c("UserArea"),
            _c("Favorites"),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-header__mobile-menu" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "hamburger close-sidebar-btn hamburger--elastic",
              class: { "is-active": _vm.isOpen },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.toggleMobile("closed-sidebar-open")
                },
              },
            },
            [_vm._m(1)]
          ),
        ]),
      ]),
      _c("div", { staticClass: "app-header__menu" }, [
        _c("div", { staticClass: "app-header-right" }, [_c("UserArea")], 1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }