var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-card",
    {
      staticClass: "custom-card",
      class: [
        _vm.topClass,
        _vm.noMarginPadding ? "padding-margin-none" : "",
        _vm.isDense ? "default-no-margin" : "",
      ],
      attrs: { bordered: "", flat: "" },
    },
    [
      !_vm.noHeaderCard
        ? _c(
            "q-card-section",
            { staticClass: "q-pa-xs cardhead", class: "bg-" + _vm.bgClass },
            [
              _c(
                "div",
                { staticClass: "row items-center no-wrap" },
                [
                  _vm.isTitle
                    ? _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-h6",
                            class: "text-" + _vm.titleClass,
                          },
                          [
                            _vm.isCardIcon
                              ? _c("i", {
                                  staticClass: "pe-7s-angle-right-circle",
                                  class: "text-" + _vm.titleClass,
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(_vm.convertTitle) + " "),
                            _vm._t("card-description"),
                          ],
                          2
                        ),
                      ])
                    : [_vm._t("card-title-custom")],
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [_vm._t("card-button")],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto cardselectarea" },
                    [_vm._t("card-select")],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _vm.collapsed
                        ? _c("q-btn", {
                            staticClass: "card-collapse",
                            attrs: {
                              flat: "",
                              dense: "",
                              color: "grey-6",
                              icon: _vm.expanded
                                ? "keyboard_arrow_up"
                                : "keyboard_arrow_down",
                            },
                            on: {
                              click: function ($event) {
                                _vm.expanded = !_vm.expanded
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      !_vm.noHeaderCard ? _c("q-separator") : _vm._e(),
      _c(
        "q-card-section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expanded,
              expression: "expanded",
            },
          ],
          staticClass: "q-pa-sm row cardheader customCardbody",
          style: _vm.height
            ? {
                height: _vm.height,
                "min-height": _vm.height,
                "max-height": _vm.height,
              }
            : { height: "auto" },
        },
        [_vm._t("card-detail")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }