<template>
  <div>
    <div :class="headerbg" class="app-header header-shadow horizon-header">
      <div class="logo-src"></div>
      <div class="app-header__content">
        <div class="app-header-left">
          <div class="header__pane ml-auto menucollapse">
            <b-dropdown menu-class="dropdown-menu-xl dropdown-menu-full" variant="link" no-caret>
              <span slot="button-content">
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic">
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
                </button>
              </span>
              <div class="horizon-full-menu-area">
                <ul v-if="permission_routers && permission_routers.length > 0" class="under-menu-list">
                  <li
                    v-for="(sceondMenu, idx2) in permission_routers"
                    :key="idx2"
                    class="under-menu-item">
                    <div :class="['sceond-menu', (!sceondMenu.child ? 'sceond-menu-nochild cursor-pointer' : '')]" @click="linkClick(sceondMenu, false)"><i class="material-icons">arrow_right</i> {{ sceondMenu.title }}
                    </div>
                    <div class="third-menu">
                      <div v-for="(thirdMenu, idx3) in sceondMenu.child"
                        :key="idx3"
                        class="third-menu-item">
                        <i class="material-icons under-muenu-title">arrow_right</i>
                        <a v-if="!thirdMenu.child || thirdMenu.child.length <= 0"
                          class="third-menu-button cursor-pointer" 
                          @click="linkClick(thirdMenu, false)">
                          {{thirdMenu.title}}
                        </a>
                        <span v-else>
                          {{thirdMenu.title}}
                          <div class="third-menu-pop">
                            <div 
                              v-for="(lastMenu, idx4) in thirdMenu.child" 
                              :key="idx4" 
                            >
                              <a 
                                class="third-menu-button cursor-pointer" 
                                @click="linkClick(lastMenu, false)">
                                <i class="material-icons under-muenu-title">arrow_right</i> 
                                {{lastMenu.title}}
                              </a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </b-dropdown>
          </div>
          <div class="app-header-left">
            <TabMenus />
          </div>
        </div>
        <div class="app-header-right">
          <MenuSearch />
          <ChangeLanuage />
          <ShortTabMenus />
          <HeaderZoom />
          <HeaderDots />
          <UserArea />
          <Favorites />
        </div>
      </div>
      <div class="app-header__mobile-menu">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="app-header__menu">
        <div class="app-header-right">
          <UserArea />
        </div>
      </div>
      <!-- <div class="break"></div> -->
    </div>
  </div>
</template>

<script>
// import SearchBox from "./Header/SearchBox";
// import MegaMenu from "./Header/MegaMenu";
import MenuSearch from "./Header/MenuSearch";
import TabMenus from "./Header/TabMenus";
import ShortTabMenus from "./Header/TabShortMenus";
import HeaderDots from "./Header/HeaderDots";
import UserArea from "./Header/HeaderUserArea";
import Favorites from "./Header/HeaderFavorite";
import HeaderZoom from "./Header/HeaderZoom";
import ChangeLanuage from "./Header/ChangeLanuage";


import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters } from 'vuex'

library.add(faEllipsisV);
export default {
  name: "Header",
  components: {
    MenuSearch,
    TabMenus,
    ShortTabMenus,
    // SearchBox,
    // MegaMenu,
    HeaderDots,
    UserArea,
    Favorites,
    HeaderZoom,
    ChangeLanuage,
    // "font-awesome-icon": FontAwesomeIcon
  },
  computed: {
    ...mapGetters([
      'permission_routers',
    ]),
    secondMenus() {
      let secondMenus = [];
      if (this.permission_routers && this.permission_routers.length > 0) {
        let selectMenu = this.$_.find(this.permission_routers, { name: this.activeMenu });
        if (selectMenu && selectMenu.child && selectMenu.child.length > 0) {
          secondMenus = selectMenu.child
        }
      }
      return secondMenus;
    },
  },
  data() {
    return {
      isTopMenuOpen: false,
      isOpen: false,
      isOpenMobileMenu: false,
      activeMenu: '',
    };
  },
  props: {
    headerbg: String
  },
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    if (this.permission_routers && this.permission_routers.length > 0) {
      this.activeMenu = this.$_.clone(this.permission_routers[0].name);
    }
  },
  methods: {
    toggleMenu() {
      this.isTopMenuOpen = !this.isTopMenuOpen;
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    linkClick(_link) {
      if (_link.href) {
        this.$router.push(_link);
        var _selectedMenu = document.getElementsByClassName('dropdown-menu-full')[0];
        if (_selectedMenu) {
          _selectedMenu.classList.remove('show');
        }
      }
    },
    hideLastMenuPop(thirdMenu) {
      this.$set(thirdMenu, 'menuOpen', null)
    }
  }
};
</script>
<style lang="sass">
.menucollapse .hamburger-inner, .menucollapse .hamburger-inner::before, .menucollapse .hamburger-inner::after
  background: #fff !important

.break
  flex-basis: 100%
  height: 0
.top-menu-item-group-head
  padding: 6px 16px 16px 16px
.top-menu-item-group
  background: #fff
  width: 100%
  padding: 0
  justify-content: center
  button
    margin-right: 0px !important

.horizon-full-menu-area
  background: #F1F6F9
  .horizon-full-menu-items
    justify-content: center
  button
    padding: 5px 10px 0px 10px !important
  .under-menu-list
    column-count: 5
    padding: 10px !important
  .under-menu-item
    flex-wrap: inherit !important
    margin: 0
    display: grid
    grid-template-rows: 1fr auto
    margin-bottom: 10px
    break-inside: avoid
  .sceond-menu
    width: 100%
    text-align: left
    padding-left: 20px
    height: 36px
    line-height: 34px 
    // border: 1px solid #0072c6
    background: #25476a
    border-radius: 16px
    color: #fff
  .sceond-menu-nochild
    border: 1px solid #25476a !important
    background: #fff !important
    color: #25476a !important
  .third-menu
    width: 100%
    padding: 14px 10px 14px 14px
    .third-menu-item
      padding-bottom: 6px
  .third-menu-button:hover
    color: #0072c6 !important
    text-decoration: underline !important
.last-menu-area
  padding: 0 10px 0 0 !important
  min-height: 30px !important
.dropdown-menu-full
  width: 99vw !important
  min-height: 200px !important
  height: auto !important
  z-index: 999 !important
  background: #F1F6F9
  top: 8px !important
.third-menu-pop
  position: relative
  top: 5px
  left: 30px
  line-height: 22px
</style>
