/**
 * 수정용 config
 */
let transactionConfig = {
  auth: {
    login: {
      insert: {
        url: '/api/auth/login',
      },
      logout: {
        url: '/api/auth/logout',
      },
      update: {
        url: '/api/auth/logout/{0}',
      },
      changepw: {
        url: '/api/auth/user/changepw',
      },
      nextChangePw: {
        url: '/api/auth/user/nextChangePw',
      },
    },
  },
  com: {
    upload: {
      uploading: {
        url: "/api/com/upload"
      },
      save: {
        url: "/api/com/update/taskKey"
      },
      delete: {
        url: "/api/com/upload/{0}"
      },
      allDelete: {
        url: "/api/com/upload/all/{0}/{1}"
      },
      copy: {
        url: "/api/com/upload/copy"
      },
    }
  },
  wod: {
    workreq: {
      insert: {
        url: '/api/wod/workreq',
      },
      update: {
        url: '/api/wod/workreq',
      },
      delete: {
        url: '/api/wod/workreq/{0}',
      },
    },
    workreqdept: {
      insert: {
        url: '/api/wod/workreq/dept',
      },
      delete: {
        url: '/api/wod/workreq/dept/del',
      },
    },
    workorder: {
      daily: {
        insert: {
          url: '/api/wod/workorder/result/work/daily'
        },
        update: {
          url: '/api/wod/workorder/result/work/daily'
        },
      },
      plan: {
        insert: {
          url: '/api/wod/workorder/plan'
        },
        copy: {
          url: '/api/wod/workorder/plan/copy'
        },
        update: {
          url: '/api/wod/workorder/plan'
        },
        start: {
          url: '/api/wod/workorder/plan/start'
        },
        cancel: {
          url: '/api/wod/workorder/plan/cancel'
        },
        delete: {
          url: '/api/wod/workorder/plan/{0}'
        },
        cost: {
          save: {
            url: '/api/wod/workorder/plan/cost'
          },
          delete: {
            url: '/api/wod/workorder/plan/cost'
          },
        },
        equip: {
          insert: {
            url: '/api/wod/workorder/plan/equip'
          },
          delete: {
            url: '/api/wod/workorder/plan/equip'
          },
        },
        work: {
          insert: {
            url: '/api/wod/workorder/plan/work'
          },
          update: {
            url: '/api/wod/workorder/plan/work'
          },
          delete: {
            url: '/api/wod/workorder/plan/work/{0}'
          },
        },
        material: {
          save: {
            url: '/api/wod/workorder/plan/material'
          },
          delete: {
            url: '/api/wod/workorder/plan/material'
          },
        },
        service: {
          save: {
            url: '/api/wod/workorder/plan/service'
          },
          delete: {
            url: '/api/wod/workorder/plan/service'
          },
        },
      },
      result: {
        update: {
          url: '/api/wod/workorder/result'
        },
        equip: {
          insert: {
            url: '/api/wod/workorder/result/equip'
          },
          delete: {
            url: '/api/wod/workorder/result/equip'
          },
        },
        cost: {
          save: {
            url: '/api/wod/workorder/result/cost'
          },
          delete: {
            url: '/api/wod/workorder/result/cost'
          },
        },
        work: {
          insert: {
            url: '/api/wod/workorder/result/work'
          },
          update: {
            url: '/api/wod/workorder/result/work'
          },
          delete: {
            url: '/api/wod/workorder/result/work/{0}'
          },
        },
        material: {
          save: {
            url: '/api/wod/workorder/result/material'
          },
          delete: {
            url: '/api/wod/workorder/result/material'
          },
        },
        service: {
          save: {
            url: '/api/wod/workorder/result/service'
          },
          delete: {
            url: '/api/wod/workorder/result/service'
          },
        },
        breakdown: {
          insert: {
            url: '/api/wod/workorder/result/breakdown'
          },
          update: {
            url: '/api/wod/workorder/result/breakdown'
          },
          delete: {
            url: '/api/wod/workorder/result/breakdown'
          },
        },
      },
      instruct: {
        update: {
          url: '/api/wod/workorder/plan/instruct'
        },
      }
    },
  },
  sp: {
    material: {
      insert: {
        url: '/api/sp/material',
      },
      update: {
        url: '/api/sp/material',
      },
      delete: {
        url: '/api/sp/material/{0}',
      },
    },
    materialin: {
      insert: {
        url: '/api/sp/material/in',
      },
      delete: {
        url: '/api/sp/material/in',
      },
    },
    materialout: {
      insert: {
        url: '/api/sp/material/out',
      },
      delete: {
        url: '/api/sp/material/out',
      },
    },
    conduct: {
      insert: {
        url: '/api/sp/material/conduct',
      },
      update: {
        url: '/api/sp/material/conduct',
      },
      delete: {
        url: '/api/sp/material/conduct/{0}',
      },
      itemdelete: {
        url: '/api/sp/material/conduct/item/del',
      },
    },
  },
  sys: {
    holiday: {
      save: {
        url: '/api/sys/holiday',
      },
      delete: {
        url: '/api/sys/holiday',
      },
    },
    appr: {
      type: {
        insert: {
          url: '/api/sys/appr/type',
        },
        update: {
          url: '/api/sys/appr/type',
        },
        delete: {
          url: '/api/sys/appr/type/{0}',
        },
      },
      request: {
        insert: {
          url: '/api/sys/appr/request',
        },
        update: {
          url: '/api/sys/appr/request',
        },
        return: {
          url: '/api/sys/appr/request/return',
        },
        retrieve: {
          url: '/api/sys/appr/request/retrieve/{0}',
        }
      },
    },
    log: {
      menulog: {
        insert: {
          url: "/api/sys/log/menulog"
        },
      }
    },
    favorite: {
      save: {
        url: "/api/sys/favorites"
      },
      insert: {
        url: "/api/sys/favorite"
      },
      delete: {
        url: "/api/sys/favorite"
      },
    },
    menu: {
      insert: {
        url: '/api/sys/men/menu',
      },
      update: {
        url: '/api/sys/men/menu',
      },
      delete: {
        url: '/api/sys/men/menu/{0}',
      },
    },
    manual: {
      insert: {
        url: '/api/sys/men/manual',
      },
      update: {
        url: '/api/sys/men/manual',
      },
    },
    sequence: {
      insert: {
        url: '/api/sys/sequence',
      },
      update: {
        url: '/api/sys/sequence',
      },
      delete: {
        url: '/api/sys/sequence/{0}',
      },
    },
    attach: {
      insert: {
        url: '/api/sys/attachsetting',
      },
      update: {
        url: '/api/sys/attachsetting',
      },
      delete: {
        url: '/api/sys/attachsetting/{0}',
      },
    },
    excelUpload: {
      insert: {
        url: '/api/sys/excelUploadSetting',
      },
      update: {
        url: '/api/sys/excelUploadSetting',
      },
    },
    batch: {
      insert: {
        url: '/api/sys/batchsetting',
      },
      update: {
        url: '/api/sys/batchsetting',
      },
      delete: {
        url: '/api/sys/batchsetting/{0}',
      },
    },
    env: {
      insert: {
        url: '/api/sys/env',
      },
      update: {
        url: '/api/sys/env',
      },
      delete: {
        url: '/api/sys/env/{0}',
      },
    },
    defect: {
      insert: {
        url: '/api/sys/defect',
      },
      update: {
        url: '/api/sys/defect',
      },
      delete: {
        url: '/api/sys/defect/{0}',
      },
    },
    notice: {
      insert: {
        url: '/api/sys/notice',
      },
      update: {
        url: '/api/sys/notice',
      },
      delete: {
        url: '/api/sys/notice/{0}',
      },
    },
    code: {
      domain: {
        save: {
          url: "/api/sys/code/domain"
        },
        delete: {
          url: "/api/sys/code/domain"
        }
      },
      group: {
        save: {
          url: "/api/sys/code/grp"
        },
        delete: {
          url: "/api/sys/code/grp"
        }
      },
      mst: {
        save: {
          url: "/api/sys/code/mst"
        },
        delete: {
          url: "/api/sys/code/mst"
        }
      }
    },
    stepper: {
      grp: {
        save: {
          url: "/api/sys/stepper/grp"
        },
        delete: {
          url: "/api/sys/stepper/grp"
        }
      },
      mst: {
        save: {
          url: "/api/sys/stepper/mst"
        },
        delete: {
          url: "/api/sys/stepper/mst"
        }
      }
    },
    lbl: {
      mst: {
        save: {
          url: "/api/sys/label/mst"
        },
        delete: {
          url: "/api/sys/label/mst"
        }
      },
      dtl: {
        insert: {
          url: '/api/sys/label/dtl',
        },
        update: {
          url: '/api/sys/label/dtl',
        },
        delete: {
          url: '/api/sys/label/dtl/{0}',
        },
      }
    },
    msg: {
      mst: {
        save: {
          url: "/api/sys/msg/mst"
        },
        delete: {
          url: "/api/sys/msg/mst"
        }
      },
      dtl: {
        insert: {
          url: '/api/sys/msg/dtl',
        },
        update: {
          url: '/api/sys/msg/dtl',
        },
        delete: {
          url: '/api/sys/msg/dtl/{0}',
        },
      }
    },
    auth: {
      grp: {
        insert: {
          url: '/api/sys/auth/grp',
        },
        update: {
          url: '/api/sys/auth/grp',
        },
        delete: {
          url: '/api/sys/auth/grp/{0}',
        },
      },
      menu: {
        add: {
          url: '/api/sys/auth/menu/add',
        },
        save: {
          url: '/api/sys/auth/menu',
        },
        delete: {
          url: '/api/sys/auth/menu',
        },
      },
      dept: {
        insert: {
          url: '/api/sys/auth/dept',
        },
        delete: {
          url: '/api/sys/auth/dept',
        },
      },
      user: {
        insert: {
          url: '/api/sys/auth/user',
        },
        delete: {
          url: '/api/sys/auth/user',
        },
      },
      deptauth: {
        insert: {
          url: '/api/sys/auth/deptauth',
        },
        delete: {
          url: '/api/sys/auth/deptauth',
        },
      },
      userauth: {
        insert: {
          url: '/api/sys/auth/userauth',
        },
        delete: {
          url: '/api/sys/auth/userauth',
        },
      },
      plant: {
        insert: {
          url: '/api/sys/auth/plant',
        },
      },
    },
  },
  sop: {
    ibm: {
      improve: {
        insert: {
          url: "/api/sop/ibm/improvement",
          multi: {
            url: "/api/sop/ibm/improvements"
          },
        },
        update: {
          url: "/api/sop/ibm/improvement"
        },
        delete: {
          url: "/api/sop/ibm/improvement/{0}"
        },
        approval: {
          url: "/api/sop/ibm/improvement/approval"
        },
      },
    },
    swp: {
      workChekItem: {
        save: {
          url: "/api/sop/swp/checkItem"
        },
      },
      gas: {
        save: {
          url: "/api/sop/swp/gas"
        },
      },
      map: {
        save: {
          url: "/api/sop/swp/map"
        },
      },
      workPermit: {
        insert: {
          url: "/api/sop/swp/workPermit"
        },
        update: {
          url: "/api/sop/swp/workPermit"
        },
        delete: {
          url: "/api/sop/swp/workPermit/{0}"
        },
        approval: {
          url: "/api/sop/swp/workPermit/approval"
        },
        over: {
          url: "/api/sop/swp/workPermit/over"
        },
        cancel: {
          url: "/api/sop/swp/workPermit/cancel"
        },
        reqApproval: {
          url: "/api/sop/swp/workPermit/reqApproval"
        },
        complete: {
          url: "/api/sop/swp/workPermit/complete"
        },
      },
    },
    min: {
      equipment: {
        plan: {
          daily: {
            insert: {
              url: '/api/sop/min/equipment/daily/plan'
            },
            update: {
              url: '/api/sop/min/equipment/daily/plan'
            },
            complete: {
              url: '/api/sop/min/equipment/daily/plan/complete'
            },
            delete: {
              url: '/api/sop/min/equipment/daily/plan/{0}'
            }
          },
          insert: {
            url: '/api/sop/min/equipment/plan'
          },
          update: {
            url: '/api/sop/min/equipment/plan'
          },
          complete: {
            url: '/api/sop/min/equipment/plan/complete'
          },
          delete: {
            url: '/api/sop/min/equipment/plan'
          }
        },
        result: {
          daily: {
            insert: {
              url: '/api/sop/min/equipment/daily/result'
            },
            update: {
              url: '/api/sop/min/equipment/daily/result'
            },
            complete: {
              url: '/api/sop/min/equipment/daily/result/complete'
            },
            delete: {
              url: '/api/sop/min/equipment/daily/result/{0}'
            },
            newItem: {
              url: '/api/sop/min/equipment/daily/new/result'
            }
          },
          insert: {
            url: '/api/sop/min/equipment/result'
          },
          update: {
            url: '/api/sop/min/equipment/result'
          },
          updates: {
            url: '/api/sop/min/equipment/results'
          },
          updateItem: {
            url: '/api/sop/min/equipment/result/items'
          },
          complete: {
            url: '/api/sop/min/equipment/result/complete'
          },
          completes: {
            url: '/api/sop/min/equipment/result/completes'
          },
          delete: {
            url: '/api/sop/min/equipment/result'
          }
        },
        improve: {
          insert: {
            url: "/api/sop/min/equipment/improve"
          },
          update: {
            url: "/api/sop/min/equipment/improve"
          },
          delete: {
            url: "/api/sop/min/equipment/improve"
          }
        }
      },
      maintenance: {
        plan: {
          insert: {
            url: '/api/sop/min/maintenance/plan'
          },
          update: {
            url: '/api/sop/min/maintenance/plan'
          },
          complete: {
            url: '/api/sop/min/maintenance/plan/complete'
          },
          delete: {
            url: '/api/sop/min/maintenance/plan'
          }
        },
        result: {
          insert: {
            url: '/api/sop/min/maintenance/result'
          },
          update: {
            url: '/api/sop/min/maintenance/result'
          },
          updates: {
            url: '/api/sop/min/maintenance/results'
          },
          complete: {
            url: '/api/sop/min/maintenance/result/complete'
          },
          completes: {
            url: '/api/sop/min/maintenance/result/completes'
          },
          delete: {
            url: '/api/sop/min/maintenance/result'
          }
        }
      }
    },
    safetycheck: {
      item: {
        save: {
          url: "/api/sop/min/safetycheck/item"
        },
        delete: {
          url: "/api/sop/min/safetycheck/item"
        }
      },
      plan: {
        insert: {
          url: "/api/sop/min/safetycheck/plan"
        },
        update: {
          url: "/api/sop/min/safetycheck/plan"
        },
        delete: {
          url: "/api/sop/min/safetycheck/plan/{0}"
        },
        complete: {
          url: "/api/sop/min/safetycheck/plan/{0}"
        },
      },
      result: {
        newItem: {
          url: "/api/sop/min/safetycheck/result/new"
        }
      }
    },
  },
  mdm: {
    tool: {
      save: {
        url: "/api/mdm/tool"
      },
      delete: {
        url: "/api/mdm/tool"
      },
    },
    costcenter: {
      save: {
        url: "/api/mdm/cost/center"
      },
      delete: {
        url: "/api/mdm/cost/center"
      },
    },
    sws: {
      mst: {
        insert: {
          url: "/api/mdm/sws/sws"
        },
        update: {
          url: "/api/mdm/sws/sws"
        },
        delete: {
          url: "/api/mdm/sws/sws/{0}"
        },
        copy: {
          url: "/api/mdm/sws/sws/copy"
        }
      },
      work: {
        insert: {
          url: "/api/mdm/sws/work"
        },
        update: {
          url: "/api/mdm/sws/work"
        },
        delete: {
          url: "/api/mdm/sws/work/{0}"
        },
      },
      equip: {
        save: {
          url: "/api/mdm/sws/equip"
        },
        delete: {
          url: "/api/mdm/sws/equip"
        },
      },
      cost: {
        save: {
          url: "/api/mdm/sws/cost"
        },
        delete: {
          url: "/api/mdm/sws/cost"
        },
      },
      material: {
        save: {
          url: "/api/mdm/sws/material"
        },
        delete: {
          url: "/api/mdm/sws/material"
        },
      },
      service: {
        save: {
          url: "/api/mdm/sws/service"
        },
        delete: {
          url: "/api/mdm/sws/service"
        },
      },
      tool: {
        save: {
          url: "/api/mdm/sws/tool"
        },
        delete: {
          url: "/api/mdm/sws/tool"
        },
      },
      worker: {
        save: {
          url: "/api/mdm/sws/worker"
        },
        delete: {
          url: "/api/mdm/sws/worker"
        },
      },
    },
    deptmanager: {
      save: {
        url: "/api/mdm/dept/manager"
      },
      delete: {
        url: "/api/mdm/dept/manager"
      },
    },
    user: {
      insert: {
        url: "/api/mdm/hrm/user"
      },
      update: {
        url: "/api/mdm/hrm/user"
      },
      delete: {
        url: "/api/mdm/hrm/user/{0}"
      },
      changepw: {
        url: "/api/mdm/hrm/changepw"
      },
      reset: {
        url: '/api/mdm/hrm/resetpwd',
      },
      cycle: {
        url: '/api/mdm/hrm/user/cycle',
      }
    },
    dept: {
      insert: {
        url: "/api/mdm/dim/dept"
      },
      update: {
        url: "/api/mdm/dim/dept"
      },
      delete: {
        url: "/api/mdm/dim/dept/{0}"
      },
    },
    loc: {
      insert: {
        url: "/api/mdm/loc/funcloc"
      },
      update: {
        url: "/api/mdm/loc/funcloc"
      },
      delete: {
        url: "/api/mdm/loc/funcloc/{0}"
      },
    },
    bom: {
      insert: {
        url: "/api/mdm/bom/bom"
      },
      update: {
        url: "/api/mdm/bom/bom"
      },
      delete: {
        url: "/api/mdm/bom/bom/{0}"
      },
    },
    process: {
      insert: {
        url: "/api/mdm/pim/process"
      },
      update: {
        url: "/api/mdm/pim/process"
      },
      delete: {
        url: "/api/mdm/pim/process/{0}"
      },
    },
    cim: {
      vendor: {
        insert: {
          url: "/api/mdm/cim/vendor"
        },
        update: {
          url: "/api/mdm/cim/vendor"
        },
        delete: {
          url: "/api/mdm/cim/vendor/{0}"
        },
        reset: {
          url: "/api/mdm/cim/vendorpwd"
        },
        resetall: {
          url: "/api/mdm/cim/vendorpwd/all"
        },
        user: {
          save: {
            url: "/api/mdm/cim/vendoruser"
          },
          delete: {
            url: "/api/mdm/cim/vendoruser"
          },
        },
        item: {
          save: {
            url: "/api/mdm/cim/vendoreval/item"
          },
          delete: {
            url: "/api/mdm/cim/vendoreval/item"
          },
        },
        type: {
          save: {
            url: "/api/mdm/cim/vendoreval/type"
          },
          delete: {
            url: "/api/mdm/cim/vendoreval/type"
          },
        },
        attach: {
          save: {
            url: "/api/mdm/cim/vendor/attach"
          },
          delete: {
            url: "/api/mdm/cim/vendor/attach"
          },
        },
        result: {
          insert: {
            url: "/api/mdm/cim/vendoreval/result"
          },
          update: {
            url: "/api/mdm/cim/vendoreval/result"
          },
          delete: {
            url: "/api/mdm/cim/vendoreval/result/{0}"
          },
          user: {
            save: {
              url: "/api/mdm/cim/vendoreval/resultuser"
            },
          },
          item: {
            save: {
              url: "/api/mdm/cim/vendoreval/resultitem"
            },
            delete: {
              url: "/api/mdm/cim/vendoreval/resultitem"
            },
          },
        },
        council: {
          insert: {
            url: "/api/sop/vendor/council"
          },
          update: {
            url: "/api/sop/vendor/council"
          },
          delete: {
            url: "/api/sop/vendor/council/{0}"
          },
          improve: {
            update: {
              url: "/api/sop/vendor/council/improve"
            },
            delete: {
              url: "/api/sop/vendor/council/improve"
            }
          }
        }
      },
    },
    sop: {
      job: {
        insert: {
          url: "/api/mdm/sop/sop"
        },
        update: {
          url: "/api/mdm/sop/sop"
        },
        delete: {
          url: "/api/mdm/sop/sop/{0}"
        },
        revision: {
          url: "/api/mdm/sop/sop/revision/{0}"
        },
      },
      step: {
        save: {
          url: "/api/mdm/sop/step"
        },
        delete: {
          url: "/api/mdm/sop/step"
        },
      },
      hazard: {
        save: {
          url: "/api/mdm/sop/hazard"
        },
        delete: {
          url: "/api/mdm/sop/hazard"
        },
      },
      reason: {
        save: {
          url: "/api/mdm/sop/sop"
        },
        update: {
          url: "/api/mdm/sop/sop"
        },
        delete: {
          url: "/api/mdm/sop/sop"
        },
      },
      mm: {
        insert: {
          url: "/api/mdm/sop/mm"
        },
        delete: {
          url: "/api/mdm/sop/mm"
        },
      },
      chem: {
        insert: {
          url: "/api/mdm/sop/chem"
        },
        delete: {
          url: "/api/mdm/sop/chem"
        },
      },
    },
    equipment: {
      info: {
        insert: {
          url: "/api/mdm/fim/equipment"
        },
        update: {
          url: "/api/mdm/fim/equipment"
        },
        delete: {
          url: "/api/mdm/fim/equipment"
        },
      },
      spec: {
        save: {
          url: "/api/mdm/fim/equipment/spec"
        },
        delete: {
          url: "/api/mdm/fim/equipment/spec"
        },
        mst: {
          save: {
            url: "/api/mdm/fim/equipment/spec/mst"
          },
          delete: {
            url: "/api/mdm/fim/equipment/spec/mst"
          },
        }
      },
      operation: {
        update: {
          url: "/api/mdm/fim/equipment/operation"
        },
      },
      breakdown: {
        save: {
          url: '/api/mdm/fim/equipment/breakdown'
        },
        delete: {
          url: '/api/mdm/fim/equipment/breakdown'
        },
      },
      operate: {
        save: {
          url: '/api/mdm/fim/equipment/operate'
        },
        delete: {
          url: '/api/mdm/fim/equipment/operate'
        },
      },
      material: {
        save: {
          url: "/api/mdm/fim/equipment/material"
        },
        delete: {
          url: "/api/mdm/fim/equipment/material"
        },
      },
      rank: {
        save: {
          url: "/api/mdm/fim/equipment/rank"
        },
      },
      class: {
        insert: {
          url: '/api/mdm/equipment/class',
        },
        update: {
          url: '/api/mdm/equipment/class',
        },
        insItem: {
          insert: {
            url: '/api/mdm/equipment/class/insitem',
          },
          update: {
            url: '/api/mdm/equipment/class/insitem',
          },
          delete: {
            url: '/api/mdm/equipment/class/insitem',
          },
        },
        daily: {
          save: {
            url: "/api/mdm/fim/equipment/daily"
          },
          delete: {
            url: "/api/mdm/fim/equipment/daily"
          },
        },
        infoItem: {
          insert: {
            url: '/api/mdm/equipment/class/infoitem',
          },
          update: {
            url: '/api/mdm/equipment/class/infoitem',
          },
          delete: {
            url: '/api/mdm/equipment/class/infoitem',
          },
        },
        item: {
          daily: {
            save: {
              url: '/api/mdm/equipment/daily/item',
            },
            delete: {
              url: '/api/mdm/equipment/daily/item',
            },
          },
          deterior: {
            save: {
              url: '/api/mdm/equipment/deterior/item',
            },
            delete: {
              url: '/api/mdm/equipment/deterior/item',
            },
          },
          equip: {
            save: {
              url: '/api/mdm/equipment/item/equipment',
            },
            delete: {
              url: '/api/mdm/equipment/item/equipment',
            },
            reset: {
              daily: {
                url: '/api/mdm/fim/equipment/resetitem/daily',
              },
              deterior: {
                url: '/api/mdm/fim/equipment/resetitem/deterior',
              },
            },
          },
          save: {
            url: '/api/mdm/equipment/class/item',
          },
          delete: {
            url: '/api/mdm/equipment/class/item',
          },
        },
      },
      spareParts: {
        in: {
          save: {
            url: '/api/mdm/equipment/sparepart/in',
          },
          delete: {
            url: '/api/mdm/equipment/sparepart/in',
          },
        },
        out: {
          save: {
            url: '/api/mdm/equipment/sparepart/out',
          },
          delete: {
            url: '/api/mdm/equipment/sparepart/out',
          },
        }
      },
      item: {
        daily: {
          save: {
            url: '/api/mdm/equipment/daily/item',
          },
          delete: {
            url: '/api/mdm/equipment/daily/item',
          },
        },
      },
    },
  },
  pm: {
    mst: {
      insert: {
        url: '/api/pm/mst',
      },
      update: {
        url: '/api/pm/mst',
      },
      delete: {
        url: '/api/pm/mst/{0}',
      },
      copy: {
        url: '/api/pm/mst/copy',
      },
      work: {
        insert: {
          url: "/api/pm/mst/work"
        },
        update: {
          url: "/api/pm/mst/work"
        },
        delete: {
          url: "/api/pm/mst/work/{0}"
        },
      },
      equip: {
        save: {
          url: "/api/pm/mst/equip"
        },
        delete: {
          url: "/api/pm/mst/equip"
        },
      },
      cost: {
        save: {
          url: "/api/pm/mst/cost"
        },
        delete: {
          url: "/api/pm/mst/cost"
        },
      },
    },
  },
  kpi: {
    target: {
      save: {
        url: "/api/kpi/target"
      },
    }
  }
};

export default transactionConfig;
