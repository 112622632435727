<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div class="fix-height" :class="[counter ? 'c-text-counter' : '']">
    <c-text-string v-if="type!=='number'"
      :name="name"
      :placeholder="placeholder"
      :editable="editable"
      :maxlength="maxlength"
      :beforeIcon="beforeIcon"
      :prependIcon="prependIcon"
      :appendIcon="appendIcon"
      :afterIcon="afterIcon"
      :required="required"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :type="type"
      :counter="counter"
      :close="close"
      :search="search"
      :detail="detail"
      :prefix="prefix"
      :suffix="suffix"
      :label="label"
      :mask="mask"
      :beforeText="beforeText"
      :dense="dense"
      :phoneNumberCheck="phoneNumberCheck"
      :bizNumberCheck="bizNumberCheck"
      v-model="vValue"
      @dataChange="dataChange"
      @searchPop="searchPop"
      @detailPop="detailPop"
      @callback="callback"
    />
    <c-text-number v-else
      :name="name"
      :placeholder="placeholder"
      :editable="editable"
      :maxlength="maxlength"
      :beforeIcon="beforeIcon"
      :prependIcon="prependIcon"
      :appendIcon="appendIcon"
      :afterIcon="afterIcon"
      :required="required"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :type="type"
      :counter="counter"
      :close="close"
      :search="search"
      :detail="detail"
      :prefix="prefix"
      :suffix="suffix"
      :label="label"
      :mask="mask"
      :beforeText="beforeText"
      :dense="dense"
      :numberOptions="numberOptions"
      :customClass="customClass"
      v-model="vValue"
      @dataChange="dataChange"
      @searchPop="searchPop"
      @detailPop="detailPop"
      @callback="callback"
    />
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-text',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: Number,
    },
    beforeIcon: {
      type: [String, Array],
    },
    prependIcon: {
      type: [String, Array],
    },
    appendIcon: {
      type: [String, Array],
    },
    afterIcon: {
      type: [String, Array],
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text', // text password textarea email search tel file number url time date
    },
    counter: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    beforeText: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    numberOptions: {
      type: Object,
      default: () => {
        return {
          currency: null,
          // hideNegligibleDecimalDigitsOnFocus: true,
          // precision: 0
        }
      }
    },
    phoneNumberCheck: {
      type: Boolean,
      default: false,
    },
    bizNumberCheck: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String, 
      default: 'text-right' // text-left text-center
    },
  },
  data() {
    return {
      vValue: '',
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모 v-model 값이 변경되었을 경우 바인딩
    value() {
      this.vValue = this.value;
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    /**
     * 컴포넌트가 생성되기전에 값이 들어감에 따라 값의 변화를 읽어들이지 못함으로 시간을 두어 값을 적용
     */
    setTimeout(() => {
      this.vValue = this.value;
    }, 50);
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    dataChange(value) {
      this.$emit('input', value);
      this.$emit('dataChange', value);
    },
    searchPop(value) {
      this.$emit('searchPop', value);
    },
    detailPop(value) {
      this.$emit('detailPop', value);
    },
    callback(_item) {
      this.$emit(_item.callbackName, _item);
    }
  },
};
</script>
<style lang="sass">
.customText-btn
  .q-field__after>*+*
      margin-left: 0px
  .q-field__after
    padding-left: 0px !important
    margin-top: 3px !important
    // padding-top: 5px
    .q-btn
      height: 31px
      width: 18px
      .material-icons
        padding-top:3px
  .q-field__innerbtn
    padding-right: 0px !important
.customText
  .material-icons
    font-size: 0.8rem
  .textCloseIcon
    display: none
  // .textOtherIcon
  //   font-size: 1.2rem
  .q-field__label
    margin-left: 5px
  .q-field__native
    font-size: 0.95em !important
  .q-field__before
    padding-top: 5px
.customText:hover
  .textCloseIcon
    display: block
.q-field--outlined .q-field__control:after
  border-top: 1px solid transparent !important
  border-right: 1px solid transparent !important
  border-left: 1px solid transparent !important

.customText.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.q-field--disabled.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  background: #F8F9FA !important
  input
    color: tomato

.customText.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important

.customText-btn
  .q-field__inner
    padding-right: 0px !important
  .q-field__control
    border-radius: 4px 0 0 0
  .q-field__after
    border-radius: 0 4px 0 0
    border-top: 1px solid rgba(0,0,0,0.15) !important
    border-right: 1px solid rgba(0,0,0,0.15) !important
    border-bottom: 1px solid rgba(0,0,0,0.15) !important

.customText-btn:not(.q-field--disabled)
  .q-field__after
    border: 1px solid rgba(0,0,0,0.15) !important
    border-left: 0 !important
.customText-btn.q-field--disabled
  .q-field__after
    background: #F8F9FA !important
.customText-btn.q-field--filled .q-field__control
  border-right: 0px !important
  
.default-table 
  .customText-btn
    .q-field__after 
      margin-top: 0px !important


.table-td-textlabel
  overflow-wrap: anywhere

.c-text-counter 
  .q-field__bottom
    display: flex
</style>