<!--
  목적 : 기능위치 컴포넌트
  작성자 : ldy
  Detail :
  *
  examples:
  *
  -->
<template>
  <div class="fix-height">
    <q-input
      bottom-slots
      readonly :dense="dense"
      :label="convertLabel"
      filled
      ref="customFuncLoc"
      class="customFuncLoc"
      stack-label
      :placeholder="convertPlaceholder"
      :disable="disabled || !editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="textLocationName"
      >
      <template v-slot:label>
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>

      <template v-slot:append>
        <slot name="custom-btn-icon"></slot>
        <q-icon v-if="editable&&searchFlag" name="search" @click="click" class="cursor-pointer"/>
        <q-icon v-if="editable&&closeFlag" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-input>

    <q-dialog
      ref="deptDialog"
      persistent
      @show="onShow" 
      @hide="onHide"
      content-class="mini-dialog"
      position="top"
      v-model="isOpen">
      <q-card class="dept-card dialog-layout">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-mini-dialog text-white dialog-header cursor-move">
          <div class="text-h6">기능위치</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectDept"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline color="primary" text-color="white">
                {{funcLocationName}}
              </q-chip>
              <q-input ref="filter" dense v-model="filter" label="Filter">
                <template v-slot:append>
                  <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
                </template>
              </q-input>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="dept-content p-2">
          <q-tree
            ref="customTreeFuncLoc"
            :nodes="funcLocTree"
            node-key="funcLocationCd"
            label-key="funcLocationName"
            children-key="children"
            no-nodes-label="기능위치 정보가 없습니다."
            no-results-label="필터링된 데이터가 없습니다."
            :filter="filter"
            :default-expand-all="defaultExpandAll"
            selected-color="primary"
            :selected.sync="selected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
import arrayToTree from 'array-to-tree';
export default {
  name: 'c-func-location',
  mixins: [mixinCommon],
  props: {
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'edit',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    // 라벨 텍스트
    label: {
      type: String,
      default: '기능위치', // 부서
    },
    plantCd: {
      type: String,
    },
    // 사용 여부
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    defaultExpandAll: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    },
    searchFlag: {
      type: Boolean,
      default: true,
    },
    closeFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      funcLocationCd: '',
      funcLocationName: '',
      textLocationName: '',
      placeholder: '선택',
      roleAccess: false,
      /** kdh */
      isOpen: false,
      filter: '',
      selected: null,
      funcLocTree: [],
      target: null,
      nodeDragg: null
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.checkDefault();
    },
    plantCd() {
      this.checkDefault();
    },
    selected() {
      let data = this.$refs['customTreeFuncLoc'].getNodeByKey(this.selected);

      this.funcLocationCd = this.selected;
      if (data) {
        this.funcLocationName = data.funcLocationName;
      } else {
        this.funcLocationName = '';
      }
    },
    searchFlag() {
      if (this.searchFlag === false) {
        this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      }
    },
    disabled() {
      if (this.disabled) {
        this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      } else {
        this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
        this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.click);
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.click);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
    }
  },
  methods: {
    init() {
      this.placeholder = this.type === 'search' ? 
        '전체' : // 전체
        '선택'; // 선택
      this.getList();
      if (this.searchFlag === false) {
        this.$refs['customFuncLoc'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.click);
      }
    },
    getList() {
      this.$http.url = selectConfig.mdm.loc.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd,
        useFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.funcLocs = _result.data;
        this.funcLocTree = this.convertTree(_result.data);
      },);
    },
    checkDefault() {
      this.$http.url = selectConfig.mdm.loc.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd,
        useFlag: 'Y'
      };
      this.$http.request((_result) => {
        this.funcLocs = _result.data;
        this.funcLocTree = this.convertTree(_result.data);
        
        let data = null;
        if (this.value) {
          data = this.$_.find(this.funcLocs, { funcLocationCd: this.value });
          if (!data) {
            this.funcLocationCd = null;
            this.funcLocationName = null;
          } else {
            this.funcLocationCd = data.funcLocationCd;
            this.funcLocationName = data.funcLocationName;
          }
        } else {
          this.funcLocationCd = null;
          this.funcLocationName = null;
        }
        this.textLocationName = this.funcLocationName;
        this.$emit('input', this.funcLocationCd);
        // this.$emit('setFuncLocationName', this.funcLocationName);
        this.$emit('datachange', this.funcLocationCd);
      },);
    },
    /**
     * 메뉴 트리용 기초 정보를 Tree 메뉴로 변환
     */
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upFuncLocationCd',
        customID: 'funcLocationCd',
      });
      return tree;
    },
    click() {
      if (this.editable && !this.disabled) {
        this.isOpen = true;
      }
    },
    resetFilter () {
      this.filter = ''
      this.$refs.filter.focus()
    },
    reset() {
      this.textLocationName = '';
      this.funcLocationCd = '';
      this.funcLocationName = '';
      this.$emit('input', this.funcLocationCd);
      // this.$emit('setFuncLocationName', this.funcLocationName);
      this.$emit('datachange', this.funcLocationCd);
    },
    selectDept() {
      if (this.funcLocationName) {
        this.textLocationName = this.funcLocationName;
        this.$emit('input', this.funcLocationCd);
        // this.$emit('setFuncLocationName', this.funcLocationName);
        this.$emit('datachange', this.funcLocationCd);
      }
      this.$refs['deptDialog'].hide();
    },
    onShow() {
      let dialogElements = document.getElementsByClassName("mini-dialog");
      this.target = dialogElements[dialogElements.length - 1].querySelector(".dialog-layout");
      this.nodeDragg = this.target.querySelector(".dialog-header");
      this.nodeDragg.addEventListener("mousedown", this.onGrab);

      this.$emit("onShow");
    },
    onHide() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.onLetGo);
      this.nodeDragg.removeEventListener("mousedown", this.onGrab);
      this.$emit("onHide");
    },
    onDrag(e) {
      let originalStyles = window.getComputedStyle(this.target);
      this.target.style.left =
        parseInt(originalStyles.left) + e.movementX + "px";
      this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";

      if (parseInt(originalStyles.top) + e.movementY < 0) {
        this.target.style.top = "0px"
      }
      if (parseInt(originalStyles.top) + e.movementY + 60 > window.innerHeight) {
        this.target.style.top = (window.innerHeight - 60) + "px"
      }
    },
    onLetGo() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.onLetGo);
    },
    onGrab() {
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.onLetGo);
    }
  },
};
</script>
<style lang="sass">
.dept-card
  width: 100%
  max-width: 450px !important
  height: 100%
  max-height: 520px !important
  overflow-y: hidden !important
.dept-content
  height: 100%
  max-height: 360px !important
  overflow-y: auto
.dept-card
  .bg-dialogheader
    height: 40px
    .q-btn
      .q-btn__wrapper
        padding: 0px !important
  .text-h6
    font-size: 1.2em
    line-height: 1.2em
    font-weight: 700
.customFuncLoc
  .q-field__native
    padding-left:3px
    font-size: 0.95em !important
    cursor: pointer !important
  .q-field__label
    margin-left: 5px
  .q-field__native::placeholder
    font-size: 1em !important
  .material-icons
    font-size: 0.8rem
  .deptCloseIcon
    display: none
.customFuncLoc:hover
  .deptCloseIcon
    display: block
.customFuncLoc.q-field--dense .q-field__control, .customFuncLoc.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important

.customFuncLoc.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.customFuncLoc.q-field--filled.q-field--readonly .q-field__control:before
  opacity: 0
</style>