<template>
  <div class="header-dots">
    <span slot="button-content">
      <!-- // 에스엘용 수정 이미지 제거 
      <img :src="require('@/assets/images/lsimg.png')"
                  :style="{ width: '60px', }" style="margin-right:10px">-->
      <q-btn
        flat
        dense
        round
        color="white"
        class="toplanguageshort"
        icon="language">
        <q-menu 
          anchor="bottom left" 
          self="top middle" 
          auto-close
          transition-show="jump-down"
          transition-hide="jump-up">
          <q-list class="topMenuShortLanguage">
            <q-item clickable v-ripple v-for="tag in LangItems"
              :key="tag.code"
              :name="tag.codeName"
              :class="tag.class"
              style="padding-left: 5px !important;"
              ref="tag"
              @click="setDefaultLanguage(tag)">
              <q-item-section avatar>
                <country-flag :country="tag.country" class="lanauageFlag" />
              </q-item-section>
              <q-item-section>
                <q-item-label lines="1">{{tag.codeName}}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </span>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import CountryFlag from "vue-country-flag";
export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      LangItems: [
        {
          code: 'kr',
          country: 'kr',
          codeName: 'Korea',
        },
        {
          code: 'en',
          country: 'us',
          codeName: 'English',
        },
        {
          code: 'ja',
          country: 'jp',
          codeName: 'Japan',
        },
        {
          code: 'zh_cn',
          country: 'cn',
          codeName: 'Chinise(CN)',
        },
        {
          code: 'zh_tw',
          country: 'my',
          codeName: 'Malaysia',
        },
      ],
      lang: '',
      defaultPlantName: '',
    };
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.lang = Cookies.get('language') ? Cookies.get('language') : 'kr';
      this.setClass();
    },
    setDefaultLanguage(tag) {
      Cookies.set('language',tag.code)
      window.location.reload();
    },
    setClass() {
      this.$_.forEach(this.LangItems, _item => {
        if (this.lang == _item.code) {
          this.$set(_item, 'class', 'activeLanuage')
        }
      })
    },
  }
};
</script>
<style lang="sass">
.topMenuShortDropdown
  padding-top: 0px !important
  padding-bottom: 0px !important
.toplanguageshort
  width: 26px
  height: 26px
  margin-right: 10px
  i
    margin-top: -8px
    margin-left: -6px
.topMenuShortLanguage
  min-width: 100px
  max-height: 289px
  *:hover
    text-decoration: none
  i
    font-size: 11px !important
  .q-item__label
    padding-left: 0px
  .q-item__section--avatar
    padding-right: 0px !important
    min-width: 40px !important
.lanauageFlag
  margin: 0 -0.9em 0em -0.7em !important
</style>
