<template>
  <div class="header-dots">
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right>
      <span slot="button-content">
          <q-btn
            flat
            dense
            round
            color="white"
            class="topnoti"
            icon="notifications">
            <q-badge color="red" rounded floating v-show="isBadge" :class="[isBadge,'animatedBadge']" />
          </q-btn>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner dropdown-menu-header-inner2 bg-orange-7">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-white">
            <h5 class="menu-header-title">알림</h5>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <b-tabs class="card-header-tab-animation" card>
          <b-tab class="p-3" active>
            <template slot="title">
              <span style="cursor:pointer;padding-right: 3px;" @click="goPage('/sys/appr/doc/apprTodo')">나의 결재</span>
              <q-badge rounded color="success" :label="apprCntTotal" />
            </template>
            <div class="scroll-gradient">
              <div class="scroll-area-sm">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-success"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title timeline-title2" style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')">결재할 문서 </h4>
                          <p style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')"> 결재
                            <q-badge rounded color="success" :label="apprCnt1" />
                          </p>
                          <p style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')"> 합의
                            <q-badge rounded color="success" :label="apprCnt2" />
                          </p>
                          <p style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')"> 확인
                            <q-badge rounded color="success" :label="apprCnt3" />
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title timeline-title2" style="cursor:pointer" @click="goPage('/sys/appr/doc/apprPass')">회람함 (최근 1주일)
                            <q-badge rounded color="warning" :label="apprCnt4" />
                          </h4>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
          <b-tab class="p-3">
            <template slot="title">
              업무 알림
              <q-badge rounded color="success" :label="taskCntTotal" />
            </template>
            <div class="scroll-gradient">
              <div class="scroll-area-lg">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <template v-if="isTask">
                      <template v-for="(task, idx) in tasks" >
                        <div v-if="task.cnt > 0"
                          :key="idx"
                          class="vertical-timeline-item vertical-timeline-element">
                          <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                            </span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">{{task.title}} <q-badge rounded color="success" :label="task.cnt" /></h4>
                              <p v-html="task.subTitle"></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                          </span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">알림이 없습니다.</h4>
                            <span class="vertical-timeline-element-date"></span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      apprCntUrl: '',
      taskUrl: '',
      apprCnt1: 0,
      apprCnt2: 0,
      apprCnt3: 0,
      apprCnt4: 0,
      tasks: []
    };
  },
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    apprCntTotal() {
      return this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4
    },
    taskCntTotal() {
      return this.tasks && this.tasks.length > 0 ?
        this.$_.sum(this.$_.map(this.tasks, 'cnt')) : 0
    },
    isBadge() {
      return this.apprCntTotal + this.taskCntTotal > 0 ? true : false
    },
    isTask() {
      return this.taskCntTotal > 0
    },
  },
  methods: {
    init() {
      this.apprCntUrl = selectConfig.sys.appr.list.mycnt.url;
      this.taskUrl = selectConfig.com.task.url;

      this.getTask();
      setInterval(() => {
        this.getApprCnt();
      }, 60000);
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    getApprCnt() {
      this.$http.url = this.apprCntUrl;
      this.$http.isLoading = false;
      this.$http.type = 'GET';
      this.$http.param = {
        approvalUserId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.apprCnt1 = _result.data.apprCnt1;
        this.apprCnt2 = _result.data.apprCnt2;
        this.apprCnt3 = _result.data.apprCnt3;
        this.apprCnt4 = _result.data.apprCnt4;
      },);
    },
    getTask() {
      this.$http.url = this.taskUrl;
      this.$http.isLoading = false;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.tasks = _result.data
      },);
    }
  }
};
</script>
<style lang="sass">
.topnoti
  width: 26px
  height: 26px
  i
    margin-top: -8px
    margin-left: -6px
  .q-badge
    font-size: 8px !important
    min-width: 8px !important
    min-height: 8px !important
    width: 8px !important
    height: 8px !important
    padding: 0px
    margin-top: 5px
    margin-right: 5px
</style>
