var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-dots menusearch-div" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            "toggle-class": "p-0 mr-2",
            "menu-class": "dropdown-menu-xl top-menu-search",
            variant: "link",
            "no-caret": "",
            right: "",
          },
        },
        [
          _c(
            "span",
            { attrs: { slot: "button-content" }, slot: "button-content" },
            [
              _c("q-btn", {
                staticClass: "topnoti",
                attrs: {
                  flat: "",
                  dense: "",
                  round: "",
                  color: "white",
                  icon: "manage_search",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "dropdown-menu-header mb-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "dropdown-menu-header-inner dropdown-menu-header-inner2 bg-mini-dialog",
              },
              [
                _c("div", {
                  staticClass: "menu-header-image opacity-1 dd-header-bg-2",
                }),
                _c("div", { staticClass: "menu-header-content text-white" }, [
                  _c("h5", { staticClass: "menu-header-title" }, [
                    _vm._v("메뉴명 검색"),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "nav-justified" }, [
            _c(
              "div",
              { staticClass: "q-pa-md q-gutter-sm menusearchdivtop" },
              [
                _c("c-text", {
                  ref: "filter",
                  attrs: {
                    label: "",
                    placeholder: "메뉴명 입력 후 Enter 시 메뉴가 검색됩니다.",
                  },
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
              ],
              1
            ),
            _vm.filter !== ""
              ? _c(
                  "div",
                  { staticClass: "scroll-area-lg" },
                  [
                    _c(
                      "VuePerfectScrollbar",
                      { staticClass: "scrollbar-container menusearchdivtop2" },
                      [
                        _c("q-tree", {
                          ref: "customTreeMenu",
                          attrs: {
                            nodes: _vm.menuTree,
                            "node-key": "name",
                            "label-key": "title",
                            "children-key": "child",
                            "no-results-label": "일치하는 메뉴명이 없습니다.",
                            filter: _vm.filter,
                            "default-expand-all": "",
                            selected: _vm.selected,
                          },
                          on: {
                            "update:selected": function ($event) {
                              _vm.selected = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header-generic",
                                fn: function (prop) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "row items-center" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-weight-bold text-primary",
                                          },
                                          [_vm._v(_vm._s(prop.node.title))]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2099470705
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }