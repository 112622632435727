import Vue from 'vue'
import Router from 'vue-router'
// import { LFeatureGroup } from 'vue2-leaflet';

Vue.use(Router);
// import sampleRouter from './sample/sampleRouter.js'

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
  {
    path: '/404',
    name: '404',
    hidden: true,
    meta: { layout: 'userpages' },
    component: () => import('@/pages/error/404.vue'),
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    meta: { layout: 'userpages' },
    component: () => import('@/pages/login/Login.vue'),
  },
  // {
  //   path: '/',
  //   name: 'main',
  //   hidden: true,
  //   title: '메인화면',
  //   meta: { layout: 'main' },
  //   component: () => import('@/pages/main/index.vue'),
  // },
  {
    path: '/',
    name: 'mainRouter',
    hidden: true,
    meta: { layout: 'default', menulog: 'N' },
    component: () => import('@/pages/main/mainRouter.vue'),
  },
  {
    path: '/sys/menu/manualPage',
    name: 'manual',
    hidden: true,
    title: '매뉴얼',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sys/menu/manualPage.vue'),
  },
  {
    path: '/sys/defect/defectManage',
    name: 'defect',
    hidden: true,
    title: '결함등록',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sys/defect/defectManage.vue'),
  },
];
/**
 * default route 정보 관리
 *
 * 그 외에 sample route 정보 관리
 * sample은 언제든지 쳐낼수 있도록 소스가 관리되어야 함
 */
// asyncRouterMap = asyncRouterMap.concat(sampleRouter);


export default new Router({
    scrollBehavior() {
      // return { x: 0, y: 0 }
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 400);
      // return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: asyncRouterMap,
    mode: 'history', // # 제거
})
