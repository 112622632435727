<!--
목적 : 선행 정비오더 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customEquip"
      class="customEquip"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-work-order',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'equip',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
    processCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
      workorderList: null,
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        if (this.value !== null) {
          this.vValue = this.value;
          this.getWoList();
          // this.setValueText();
        } else {
          this.$emit('input', null);
          this.valueText = '';
        }
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.listUrl = selectConfig.wod.workorder.plan.list.url;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customEquip'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customEquip'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    getWoList() {
      this.$http.url = selectConfig.wod.workorder.plan.get.url;
      this.$http.type = 'GET';
      this.$http.param = {
        workPlanId: this.vValue
      }
      this.$http.request((_result) => {
        this.workorderList = _result.data;
        this.setValueText();
      },);
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (!this.workorderList) {
          this.getWoList();
        } else 
        if (this.workorderList.length === 0) {
          this.$emit('input', null);
          this.valueText = '';
        } else {
          this.valueText = '[' + this.workorderList.woWorkPlanStepName + ']' + this.workorderList.workPlanName
        }
      } else {
        this.valueText = '';
      }
    },
    search() {
      this.popupOptions.title = '정비오더 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/wo/workorderPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.workorderList = data;
        if (this.type === 'equip') {
          this.valueText = '[' +  data[0].woWorkPlanStepName + ']' +  data[0].workPlanName
        }
        this.$emit('input', data[0].workPlanId);
        this.$emit('dataChange', data[0]);
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('dataChange', null);
    },
  },
};
</script>
<style lang="sass">
.customEquip
  padding-bottom: 14px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customEquip:hover
  .dateCloseIcon
    display: block
.customEquip.q-field--dense .q-field__control, .customEquip.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customEquip.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>